:root {
    --white: #FFFFFF;
    --blue: #0A1028;
    --orange: #FF5C01;
    --gray: #6E6E6E;
    --sky-blue: #008ADE;
    --sky-blue-contact: #39C3F9 ;
    --sky-blue-nav: #3AC8FF;
    --light-gray: #C6BDBD;
    --brown: #454545;
    --green: #00995B;
}
