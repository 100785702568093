/*@font-face {*/
/*    font-family: "Gilroy";*/
/*    src: url("../Fonts/Gilroy/Gilroy-Bold.ttf");*/
/*    src: url("../Fonts/Gilroy/Gilroy-Bold.ttf") format("truetype"),*/
/*    url("../Fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype"),*/
/*    url("../Fonts/Gilroy/Gilroy-Light.ttf") format("truetype"),*/
/*    url("../Fonts/Gilroy/Gilroy-Medium.ttf") format("truetype"),*/
/*    url("../Fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");*/
/*}*/
@font-face {
    font-family: 'Gilroy-Bold';
    src: url('../Fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Heavy';
    src: url('../Fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Light';
    src: url('../Fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Medium';
    src: url('../Fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../Fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.gilroy {
    font-family: "Gilroy-Bold", sans-serif !important;
}

.gilroy-bold {
    font-family: "Gilroy-Bold", sans-serif !important;
}
.gilroy-heavy {
    font-family: "Gilroy-Heavy", sans-serif !important;
}
.gilroy-light {
    font-family: "Gilroy-Light", sans-serif !important;
}
.gilroy-medium {
    font-family: "Gilroy-Medium", sans-serif !important;
}
.gilroy-regular {
    font-family: "Gilroy-Regular", sans-serif !important;
}


.text-white {
    color: var(--white) !important;
}

.text-orange {
    color: var(--orange);
}

.text-blue {
    color: var(--blue);
}

.text-skyblue {
    color: var(--sky-blue);
}

.text-lightgray {
    color: var(--light-gray);
}

.text-brown {
    color: var(--brown);
}

.text-green {
    color: var(--green);
}

.text-transparent {
    color: transparent !important;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}
