@import "variables.css";
@import "fonts.css";
@import "slider.css";
@import "sections.css";
@import "form.css";

html {
    scroll-behavior: smooth;
}
.home-width {
    width: 100vw;
}

.bg-blue {
    background-color: var(--blue) !important;
}

.bg-skyblue {
    background-color: var(--sky-blue) !important;
}
.bg-skyblue-nav {
    background-color: var(--sky-blue-nav) !important;
}
.bg-skyblue-contact-us {
    background-color: var(--sky-blue-contact) !important;
}

.bg-white {
    background-color: var(--white) !important;
}
.bg-white-10 {
    background: rgba(255, 255, 255, 0.1);
}

.bg-orange {
    background-color: var(--orange) !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}
.border-bottom-orange-4{
    border-bottom: 4px solid var(--orange);
}
.border-bottom-green-4{
    border-bottom: 4px solid var(--green);
}
.border-bottom-skyblue-4{
    border-bottom: 4px solid var(--sky-blue);
}
.active-link {
    border-bottom: 2px solid var(--white) !important;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    box-shadow: none !important;
}

.hero-section-text {
    /*background: rgb(13,18,38);*/
    background: radial-gradient(circle, rgba(13, 18, 38, 0.5) 22%, rgba(2, 0, 36, 0.5) 99%);
}

.bg-product-hero-section {
    background: linear-gradient(180deg, rgba(10, 16, 40, 0) 0%, #0A1028 100%);
}

.learn-more-button {
    border: 1px solid var(--blue);
    border-radius: 37px;
    width: fit-content;
}

.border-blue {
    border: 1px solid var(--blue);
}

.border-white {
    border: 1px solid var(--white);
}

.border-light-gray-2 {
    border: 2px solid var(--light-gray);
}

.bg-section-three {
    background: linear-gradient(180deg, rgba(10, 16, 40, 0) 0%, #0A1028 100%);
}

.bg-section-four {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.truncate-multi-line {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*scroll-without-scrollbar*/
.scroll-without-scrollbar::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.scroll-without-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.scroll-without-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0 !important;
    border: none !important;
}

.line-skyblue-5 {
    height: 5px;
    background: var(--sky-blue);
    width: 158px;
}

.arrow-right-skyblue {
    border: 5px solid rgba(0, 138, 222, 1)
}

.about-us-hero-section-bg {
    background: linear-gradient(269.09deg, rgba(10, 16, 40, 0.5) 6.5%, #0A1028 95.1%);
}

.team-card {
    border: 0.87px solid rgba(195, 195, 195, 1);
}

/*.game-image {*/
/*    transition: transform 0.3s ease, box-shadow 0.3s ease;*/
/*}*/
/*.game-image:hover {*/
/*    transform: scale(1.1);*/
/*    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);*/
/*}*/

.game-image {
    transition: transform 0.3s ease;
}
.game-image:hover {
    transform: scale(1.05);
}

/*.game-image {*/
/*    transition: transform 0.3s ease, box-shadow 0.3s ease;*/
/*}*/

/*.game-image:hover {*/
/*    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);*/
/*}*/

.sport-image-container {
    background-size: cover;
    opacity: 0.40;

}
.product-image-card{
    height: 895px !important;
}

.sport-item {
    background-size: cover;
    background-position: center;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    /*border-radius: 10px; !* Optional: Add border radius for rounded corners *!*/
    /*opacity: 70%;*/
    /*background-color: var(--blue);*/
}
/*.sport-item:hover {*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    padding-top: 56.25%; !* 16:9 Aspect Ratio *!*/
/*    opacity: 100%;*/
/*    !*background-color: var(--blue);*!*/

/*    !*border-radius: 10px; !* Optional: Add border radius for rounded corners *!*!*/
/*}*/

.sport-logo {
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*width: 300px;*/
    /*height: 105.56px;*/
}


.custom-list {
    list-style-type: none;
    padding-left: 0;
}

.custom-list li {
    position: relative;
    padding-left: 20px; /* Adjust as needed */
}

.custom-list li::before {
    content: '-';
    position: absolute;
    left: 0;
    font-family: 'Gilroy-Medium', sans-serif; /* Ensure the font matches */
}
.custom-toggler {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.dropdown-menu-nav-bar {
    width: 211px;
    border-radius: 0px !important;
}
.dropdown-item-nav-bar{
    color: var(--blue);
    text-decoration: none;
}
.dropdown-item-nav-bar:hover{
    color: var(--white);
    text-decoration: none;
}

.border-investor-box {
    position: relative;
    background-color: rgba(10, 16, 40, 0.5); /* Background color */
    border-radius: 20px;
    padding: 10px; /* Adjust padding as needed */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); /* Shadow */
    overflow: hidden; /* To make sure the pseudo-element stays within the border-radius */
}

.border-investor-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 2px; /* Width of the border */
    background: linear-gradient(180deg, #FFFFFF 0%, #39C3F9 100%);
    -webkit-mask: linear-gradient(#FFFFFF 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none; /* To ensure it's not interactable */
}

.shadow-investor-heading-card {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
}

.truncate-one-row {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-two-rows {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-three-rows {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.address-card {
    box-shadow: 0px 0px 20px 0px #4E4E4E33;
}

.accordion-button {
    background-color: #161D3A !important;
    border: none !important;
    outline: none !important;
    color: #FFFFFF !important;
    font-size: 20px !important;
    border-radius: 20px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    background-color: var(--sky-blue-contact) !important;
    color: var(--blue) !important;
    font-size: 20px !important;
}
.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}