.product-hero-background {
    background-size: cover;
    background-position: center;
    /*background-repeat: no-repeat;*/
}
.contact-us-hero-section-mobile{
    height: 900px;
    margin-top: -50px;
    width: 100%;
}
.contact-us-hero-section-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.contact-us-hero-section-background-mobile{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../Images/Contactus/bg-contact-us-mobile.png");
}
.investors-hero-section-mobile{
    height: 2015px;
    margin-top: -110px;
    width: 100%;
}
.investors-hero-section-background-mobile{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../Images/Investors/bg-investors-mobile.png");
}
.contact-us-hero-section-background-gradient {
    background: linear-gradient(0deg, rgba(10, 16, 40, 0.8) 0%, #0A1028 100%);
}

.blue-section-container{
    height: auto;
}
.count-card-container{
    width: 100%;
}
.count-card{
    height: 146px;
    width: 100%;
}
.count-card-mobile{
    height: 146px;
    width: 100%;
}
.e-sports-teams{
    width: 293px;
    height: 406px;

}
.news-image-discord-mobile{
    width: 200px;
    height: 151px;
}
.wallet-image-discord-mobile{
    width: 279px;
    height: 359px;
}
.investor-hero-section-mobile{
    height: 591px;
    margin-top: -50px;
    width: 100%;
}
/* Define CSS classes for different screen sizes */
@media (max-width: 1439px) {
    .bg-privacy-policy-header{
        margin-top: -110px;
        height: 18vh;
    }
    .blue-section-container{
        height: 494px !important;
    }
    .mobile-image-one{
        width: 50%;
        height: 250px;
    }
    .mobile-image-two{
        width: 50%;
        height: 250px;
    }
    .laptop-skeleton{
        width: 100%;
        height: 251.83px;
    }
    .video-container{
        height: 591px !important;
        margin-top: -110px ;
    }
    .blue-section-container{
        height: 694px;
    }
    .image-section-container {
        height: 354px !important;
        width:489.79px !important;
    }



    .product-hero-section{
        margin-top: -110px;
    }

    .product-image-card {
        height: 948px !important;
    }
    .product-hero-background {
        background-image: url('../Images/Product/bg-product-hero.png');
    }
    .contact-us-hero-section{
        margin-top: -110px;
        height: 1073px;
        width: 100%;
    }
    .contact-us-hero-section-background{
        background-image: url("../Images/Contactus/Contactus-1440.png");
    }
    .esports-section-image-responsive{
        width: 100% ;
        height: 375px;
    }
    .count-card-container{
        width: 100%;
    }
    .esport-section-home-image{
        width: 100%;
        height: 80%;
    }
    .section-home-container{
        height: auto;
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .sport-section-home-image{
        width: 100%;
        height: 100%;
    }
    .web3-section-home-image{
        width: 100%;
        height: 100%;
    }
    .product-hero-section-image{
        height: 100% !important;
        width: 100% !important;
    }
    .hero-section-video{
        width: 1440px !important;
        /*max-width: 100vw !important;*/
        object-fit: cover;
    }
    .investor-hero-section{
        margin-top: -170px;
        height: 702px;
        width: 100%;
    }
    .investor-hero-section{
        margin-top: -110px;
        height: 1087.64px;
        width: 100%;
    }
    .investor-hero-section-background{
        background-image: url("../Images/Investors/bg-investors-1440.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media (min-width: 1440px) and (max-width: 2559px) {
    .bg-privacy-policy-header{
        margin-top: -110px;
        height: 110px;
    }
    .contact-us-interested{
        height: 169px;
    }
    .contact-us-interested-text{
        font-size: 36px;
    }
    .mobile-image-one{
        width: 188.51px;
        height: 396.54px;
    }
    .mobile-image-two{
        width: 191px;
        height: 393px;
    }
    .laptop-skeleton{
        width: 792.48px;
        height: 441.83px;
    }
    .video-container{
        height: 591px !important;
        margin-top: -110px ;
    }
    .blue-section-container{
        height: 694px;
    }
    .image-section-container {
        height: 630px !important;
        width:700px !important;
    }
    .product-hero-section{
        margin-top: -110px;
    }
    .product-image-card{
        height: 702px !important;
    }

    .product-hero-background {
        background-image: url('../Images/Product/Background-product-1440.png');
    }
    .section-image-responsive{
        width: 605px !important;
        height: 440px !important;
    }
    .esports-section-image-responsive{
        width: 605px !important;
        height: 440px !important;
    }
    .contact-us-hero-section{
        margin-top: -110px;
        width: 100%;
        height: 1039px;

    }
    .contact-us-hero-section-background{
        background-image: url("../Images/Contactus/Contactus-1440.png");

    }

    .count-card-container{
        width: 90% !important;
    }
    .count-card{
        height: 146px;
        width: 100%;
    }
    .section-home-container{
        height: 694px;
    }
    .esport-section-home-image{
        width: 722.32px;
        height: 576.65px;
    }
    .sport-section-home-image{
        min-width: 100%;
        max-width: 872px;
        height: 600.25px;
    }
    .web3-section-home-image{
        width: 872px;
        height: 630.25px;
    }
    .product-hero-section-image{
        height: 506px !important;
        width: 741px !important;
    }
    .hero-section-video{
        width: 1440px !important;
        /*max-width: 100vw !important;*/
        object-fit: cover;
    }

    .news-image-product{
        width: 424.66px;
        height: 532.8px;
    }
    .teams-image-product{
        height: 110%;
        width: 110%;
    }
    .investor-hero-section{
        margin-top: -170px;
        height: 702px;
        width: 100%;
    }
    .investor-hero-section{
        margin-top: -110px;
        height: 1087.64px;
        width: 100%;
    }
    .investor-hero-section-background{
        background-image: url("../Images/Investors/bg-investors-1440.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media (min-width: 2560px) {
    .bg-privacy-policy-header{
        margin-top: -110px;
        height: 110px;
    }

    .contact-us-interested{
        height: 400px;
    }
    .contact-us-interested-text{
        font-size: 50px;
    }
    .mobile-image-one{
        width: 226.68px;
        height: 476.49px;
    }
    .mobile-image-two{
        width: 231px;
        height: 475px;
    }
    .laptop-skeleton{
        width: 936.96px;
        height: 522.38px;
    }
    .video-container{
        height: 889px !important;
        margin-top: -110px ;
    }
    .blue-section-container{
        height: 900px;
    }
    .image-section-container {
        height: 725.55px !important;
        width: 941px !important;
    }
    .product-hero-section{
        margin-top: -110px;
    }
    .product-hero-section-image{
        height: 690px !important;
        width: 1011px !important;
    }
    .product-image-card{
        height: 1430px !important;
    }

    .product-hero-background {
        background-image: url('../Images/Product/background-2560.png');
    }

    .section-image-responsive{
        width: 854px !important;
        height: 628px !important;
    }
    .esports-section-image-responsive{
        width: 854px !important;
        height: 628px !important;
    }
    .contact-us-hero-section{
        margin-top: -110px;
        height: 1189px;
        width: 100%;
    }
    .contact-us-hero-section-background{
        background-image: url("../Images/Contactus/Contact-US-2560.png");
    }

    .count-card-container{
        width: 70% !important;
    }
    .section-home-container{
        height: 900px;
    }
    .esport-section-home-image{
        width: 914px;
        height: 725.55px;
    }
    .sport-section-home-image{
        width: 1129px;
        height: 816px;
    }
    .web3-section-home-image{
        width: 1166px;
        height: 874px;
    }
    .hero-section-video{
        width: 2560px !important;
        /*max-width: 100vw !important;*/
        object-fit: cover;
    }
    .height-product-section{
        height: 832px
    }
    .news-image-product{
        width: 541.45px;
        height: 679.34px;
    }
    .teams-image-product{
        width: 922px;
        height: 743px;
    }
    .tournaments-image-product{
        width: 801px;
        height: 573px;
    }
    .education-image-product{
        width: 708px;
        height: 652.73px;
    }
    .games-image-product{
        width: 646px;
        height: 544.58px;
    }
    .matches-image-product{
        width: 588.02px;
        height: 703.33px;
    }
    .watch-image-product{
        width: 585.24px;
        height: 700px;
    }
    .players-image-product{
        width: 588.58px;
        height: 704px;
    }
    .discord-image-product{
        width: 301px;
        height: 228px;
    }
    .favourites-image-product{
        width: 560.58px;
        height: 703.34px;
    }
    .wallet-image-product{
        width: 607px;
        height: 781px;
    }
    .investor-hero-section{
        margin-top: -110px;
        height: 1305.22px;
        width: 100%;
    }
    .investor-hero-section-background{
        background-image: url("../Images/Investors/bg-investors-2560.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}