@import "variables.css";

.custom-input {
    color: var(--blue) !important; /* Text color */
    background-color: var(--white); /* Background color */
    border: none; /* Default border color */
}

.custom-input::placeholder {
    color: rgba(130, 130, 130, 1) !important;
    font-family: "Gilroy-Medium";
    font-size: 16px;
    font-weight: 400;

}

.custom-input:focus {
    box-shadow: var(--sky-blue) !important;
    outline: 2px solid var(--sky-blue) !important; /* Remove default outline */
}

.submit-button:focus {
    box-shadow: none !important;
    outline: none !important;
}